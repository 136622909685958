import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';

export const useTransactionHistory = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['history', params],
    () =>
      axios
        .get('/api/history/', {
          params,
        })
        .then(({ data }) => data),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useTransactionHistoryYears = (params, options) =>
  useQuery(
    ['historyYears', { params }],
    () => axios.get('/api/history/years/', { params }).then(({ data }) => data),
    { placeholderData: [], ...options }
  );

export const useTransactionHistoryExport = (params, options) =>
  useQuery(
    ['historyExport', { params }],
    () =>
      axios
        .get('/api/history/export/', { params, responseType: 'blob' })
        .then(({ data }) => data),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );

export const useHistoryDetails = ({ id, params = {} } = {}) =>
  useQuery(['historyDetails', id, { params }], () =>
    axios
      .get(`/api/history/${id}/`, {
        params,
      })
      .then(({ data }) => data)
  );

export const useUpdateHistoryDetails = (id, options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => axios.patch(`/api/history/${id}/`, data).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['historyDetails', id]);
        return queryClient.invalidateQueries(['history']);
      },
      ...options,
    }
  );
};

export const useHistoryLocation = (
  id,
  { page, ...params } = {}, // never include page in params here
  { enabled = true, ...options } = {}
) =>
  useQuery(
    ['historyDetails', id, 'page', { params }],
    () =>
      axios
        .get(`/api/history/locate/${id}/`, { params })
        .then(({ data }) => data),
    { enabled: id ? enabled : false, ...options }
  );

export const useAnonymousUsers = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['anonymous', params],
    () =>
      axios.get('/api/history/anonymous/', { params }).then(({ data }) => data),
    { staleTime: 60000, ...options }
  );

export const usePendingTransactions = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['pendingTransactions', params],
    () =>
      axios
        .get('/api/movements/', { params: { ...params, is_pending: true } })
        .then(({ data }) => data),
    {
      keepPreviousData: true,
      ...options,
    }
  );
