import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import axios from 'axios';
import contentDisposition from 'content-disposition';

export const useKdeys = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['kdey', params],
    () => axios.get('/api/kdey/', { params }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useKdey = (id, { options = {} } = {}) =>
  useQuery(
    ['kdey', { id }],
    () => axios.get(`/api/kdey/${id}/`).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useDeleteKdeyImage = (id, onSuccess) =>
  useMutation(
    () => axios.delete(`/api/kdey/${id}/images/`).then(({ data }) => data),
    { onSuccess }
  );

export const useUploadKdeyImage = (id, onSuccess) => {
  const [progress, setProgress] = useState(0);

  const resetProgress = () => {
    setProgress(0);
  };
  const uploadMutation = useMutation(
    (data) =>
      axios
        .post(`/api/kdey/${id}/images/`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        })
        .then(({ data }) => data),
    { onSuccess, onMutate: resetProgress }
  );

  return { progress, uploadMutation };
};

export const useCreateKdey = (onSuccess) =>
  useMutation(
    (data) => axios.post('/api/kdey/', data).then(({ data }) => data),
    { onSuccess }
  );

export const useUpdateKdey = (id, onSuccess) =>
  useMutation(
    (data) => axios.patch(`/api/kdey/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useDeleteKdey = (id) =>
  useMutation((data) =>
    axios.delete(`/api/kdey/${id}/`, data).then(({ data }) => data)
  );

export const useKdeyStockExport = (params, options) => {
  return useQuery(
    ['kdeyStockExport', { params }],
    () =>
      axios
        .get('/api/kdey/export_stock/', { params, responseType: 'blob' })
        .then(({ data, headers }) => {
          let filename;
          try {
            const { parameters } = contentDisposition.parse(
              headers['content-disposition']
            );
            filename = parameters?.filename || undefined;
          } catch (err) {
            console.warn(err);
          }

          const file = new File(
            [data],
            filename ?? 'Διαθέσιμες ποσότητες ανά ΚΔΕΥ.xlsx',
            { type: data.type }
          );

          return file;
        }),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
};
