import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQueryClient } from 'react-query';
import {
  Route,
  useHistory,
  useRouteMatch,
  Link,
  useParams,
  Switch,
} from 'react-router-dom';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Modal,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';

import useCreateUpdate from '../hooks/useCreateUpdate';

import {
  useCreateKdey,
  useDeleteKdey,
  useDeleteKdeyImage,
  useKdey,
  useKdeys,
  useKdeyStockExport,
  useUpdateKdey,
  useUploadKdeyImage,
} from '../queries/kdeys';
import { useTransactionHistoryYears } from '../queries/transactionHistory';

import { ImageModal } from '@zerowaste/components';
import SuccessMessage, { ErrorMessage } from './SuccessMessage';

function KdeyImageModal({ onClose }) {
  const { kdeyId } = useParams();

  const kdey = useKdey(kdeyId);

  const status = {
    isLoading: kdey.isLoading,
    isFetching: kdey.isFetching,
    isFetched: kdey.isFetched,
  };

  return (
    <ImageModal
      mutationId={kdeyId}
      mutationType="kdey"
      useUploadMutation={useUploadKdeyImage}
      useDeleteMutation={useDeleteKdeyImage}
      hasOneImage
      images={kdey.data?.url}
      status={status}
      onClose={onClose}
    />
  );
}

function KdeyForm({ onClose, baseUrl }) {
  const { kdeyId } = useParams();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    formData,
    getValue,
    handleChange,
    handleSubmit,
    serverError,
    isNew,
    isUpdated,
    isMutationLoading,
    type: kdey,
  } = useCreateUpdate({
    typeId: kdeyId,
    baseUrl,
    typeName: 'kdey',
    useCreateMutation: useCreateKdey,
    useUpdateMutation: useUpdateKdey,
    useType: useKdey,
  });

  const isDeletable =
    kdey.data?.has_transactions === false && kdey.data?.has_employees === false;

  const deleteKdey = useDeleteKdey(kdeyId);
  const isButtonDisabled =
    kdeyId === 'new' &&
    !(
      formData.name &&
      formData.email &&
      formData.telephone &&
      formData.address
    );

  const handleDelete = () => {
    deleteKdey.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('kdey');
          history.replace(baseUrl);
        },
      }
    );
  };

  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>
        {kdeyId === 'new' ? 'Δημιουργία ΚΔΕΥ' : 'Επεξεργασία ΚΔΕΥ'}
      </Modal.Header>
      <Modal.Content>
        {isNew && <SuccessMessage message="Το ΚΔΕΥ δημιουργήθηκε επιτυχώς!" />}
        {isUpdated && (
          <SuccessMessage message="Το ΚΔΕΥ ενημερώθηκε επιτυχώς!" />
        )}
        <Form
          autoComplete="off"
          id="kdey-form"
          loading={kdey.isLoading}
          onSubmit={handleSubmit}
        >
          <Form.Input
            required
            label="Όνομα"
            name="name"
            value={getValue('name')}
            onChange={handleChange}
          />
          <Form.Input
            required
            label="Διεύθυνση"
            name="address"
            value={getValue('address')}
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <Form.Input
              required
              type="email"
              label="Email"
              name="email"
              value={getValue('email')}
              onChange={handleChange}
            />
            <Form.Input
              required
              type="tel"
              label="Τηλέφωνο"
              name="telephone"
              value={getValue('telephone')}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.TextArea
            rows={3}
            label="Ωράριο λειτουργίας"
            placeholder={'Δε-Τρ-Τε-Πε-Πα: 08:00-15:00\nΣα: 09:00-13:00'}
            name="open_hours"
            value={getValue('open_hours')}
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <Form.Input
              label="Lat"
              name="lat"
              type="number"
              value={getValue('lat')}
              onChange={handleChange}
            />
            <Form.Input
              label="Long"
              name="lng"
              type="number"
              value={getValue('lng')}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Checkbox
            label="Βρίσκεται σε λειτουργία"
            name="is_active"
            checked={getValue('is_active')}
            onChange={(e, { checked }) =>
              handleChange(e, { name: 'is_active', value: checked })
            }
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {kdey.isFetched && isDeletable && (
              <Button
                disabled={deleteKdey.isLoading}
                loading={deleteKdey.isLoading}
                form="kdey-form"
                negative
                content="Διαγραφή"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              />
            )}
            <div>
              <Button
                disabled={isButtonDisabled}
                loading={isMutationLoading}
                form="kdey-form"
                primary
                content={kdeyId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'}
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                content={kdeyId === 'new' ? 'Ακύρωση' : 'Επιστροφή'}
              />
            </div>
          </div>
          {serverError && (
            <ErrorMessage message="Προέκυψε κάποιο σφάλμα, παρακαλώ προσπαθήστε ξανά" />
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
}

function KdeyStockExportButton() {
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedYear, setSelectedYear] = useState();

  const exportExcel = useKdeyStockExport({
    year: selectedYear,
  });

  const handleDownload = () => {
    exportExcel.refetch().then(({ data }) => {
      if (data) {
        const href = URL.createObjectURL(data);
        window.location = href;
        URL.revokeObjectURL(href);
      }
      // close export modal
      setModalOpen(false);
    });
  };

  const { data: historyYears } = useTransactionHistoryYears();
  useEffect(() => {
    if (selectedYear) return;
    setSelectedYear(historyYears[0]);
  }, [selectedYear, historyYears]);

  return (
    <>
      <Button
        active={modalOpen}
        onClick={() => setModalOpen(true)}
        icon="download"
        labelPosition="left"
        content="Απολογιστικές"
        floated="right"
      />
      <Divider clearing hidden />
      <Modal
        size="tiny"
        dimmer="inverted"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>Εξαγωγή απολογιστικών σε Excel</Modal.Header>
        <Modal.Content>
          <p>
            Για ποιο έτος επιθυμείτε να εξαγάγετε την διαθεσιμότητα τεμαχίων
            αποθήκης ανά ΚΔΕΥ;
          </p>
          <Form>
            <Form.Select
              options={historyYears.map((year) => ({
                key: year,
                value: year,
                text: year,
              }))}
              value={selectedYear}
              onChange={(e, { value }) => setSelectedYear(value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="download"
            primary
            content="Εξαγωγή"
            onClick={handleDownload}
            disabled={exportExcel.isFetching}
            loading={exportExcel.isFetching}
          />
          <Button
            floated="left"
            onClick={() => setModalOpen(false)}
            content="Ακύρωση"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default function AdminKdeys() {
  const match = useRouteMatch();
  const history = useHistory();

  const pageTitle = 'Διαχείριση ΚΔΕΥ';

  const [ordering, setOrdering] = useState('name');

  const kdeys = useKdeys({
    params: { ordering },
    options: { keepPreviousData: true },
  });

  const handleModalClose = () => {
    history.push(match.url);
  };

  const getSorted = (name) =>
    ordering === name
      ? 'descending'
      : ordering === `-${name}`
      ? 'ascending'
      : null;

  const handleHeaderClick = (name) => {
    setOrdering((oldState) => {
      if (oldState === name) {
        return `-${name}`;
      } else {
        return name;
      }
    });
  };

  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container>
        <KdeyStockExportButton />
        <Segment basic loading={kdeys.isLoading}>
          <Table
            sortable
            fixed
            celled
            selectable
            className="pointable"
            style={{
              opacity: kdeys.isFetching && kdeys.isPreviousData ? '0.5' : '1',
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={getSorted('name')}
                  onClick={() => handleHeaderClick('name')}
                >
                  Όνομα
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted('address')}
                  onClick={() => handleHeaderClick('address')}
                >
                  Διεύθυνση
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted('email')}
                  onClick={() => handleHeaderClick('email')}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted('telephone')}
                  onClick={() => handleHeaderClick('telephone')}
                >
                  Τηλέφωνο
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ pointerEvents: 'none', width: '65px' }}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {kdeys.data?.map((kdey) => (
                <Table.Row
                  key={kdey.id}
                  onClick={(e) => {
                    e.target.tagName !== 'BUTTON' &&
                      history.push(`${match.url}/${kdey.id}`);
                  }}
                >
                  <Table.Cell>{kdey.name}</Table.Cell>
                  <Table.Cell>{kdey.address}</Table.Cell>
                  <Table.Cell>{kdey.email}</Table.Cell>
                  <Table.Cell>{kdey.telephone}</Table.Cell>
                  <Table.Cell>
                    <Popup
                      content={`Επεξεργασία φωτογραφιών ΚΔΕΥ`}
                      trigger={
                        <Button
                          icon={
                            <Icon
                              name="image"
                              style={{ pointerEvents: 'none' }}
                            />
                          }
                          onClick={() => {
                            history.push(`${match.url}/${kdey.id}/image`);
                          }}
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
              {kdeys.data?.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={4} textAlign="center">
                    Δεν υπάρχουν πληροφορίες για ΚΔΕΥ
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Button
            as={Link}
            to={`${match.url}/new`}
            floated="right"
            positive
            icon="plus"
            labelPosition="left"
            content="Προσθήκη ΚΔΕΥ"
          />
          <Switch>
            <Route exact path={`${match.path}/:kdeyId(\\d+|new)`}>
              <KdeyForm onClose={handleModalClose} baseUrl={match.url} />
            </Route>
            <Route path={`${match.path}/:kdeyId(\\d+)/image`}>
              <KdeyImageModal onClose={handleModalClose} />
            </Route>
          </Switch>
        </Segment>
        <Divider section hidden />
      </Container>
    </>
  );
}
